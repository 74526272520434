<template>
  <section
      :id="!!id.length ? id : null"
      class="co-living-benefits push-v--desktop--x-large push-v--tablet--medium push-v--mobile--medium"
  >
    <div class="co-living-benefits__intro container push-v--mobile--medium push-v--desktop--medium">
      <div class="row justify-content-center">
        <div class="col-10 col-md-9 col-lg-10 ">
          <div class="row justify-content-center">
            <div class="col-12 co-living-benefits__title-wrapper">
              <h2
                  data-entrance="fade"
                  class="h4 co-living-benefits__title push-v--mobile--small push-v--tablet--small push-v--desktop--medium text-center"
              >
                {{ title }}
              </h2>
            </div>
          </div>
          <div v-if="intro" data-entrance="fade" class="row justify-content-center">
            <div class="col-12 col-lg-6 co-living-benefits__body" v-html="intro" />
          </div>
        </div>
      </div>
    </div>
    <div class="co-living-benefits__list container">
      <div class="row justify-content-center">
        <div class="col-10" style="padding-left: 0;padding-right: 0;">
          <div class="row justify-content-between">
            <div
                v-for="(item, index) in items"
                :key="index"
                
                class="co-living-benefits__item"
                :class="getItemClass"
                data-entrance="from-bottom"
            >
              <div
                  v-if="item.icon"
                  class="co-living-benefits__item-icon push-h--mobile--right--x-small push-h--tablet--right--x-small push-h--desktop--right--x-small"
                  :class="{'push-v--desktop--x-small push-v--mobile--x-small':forHome}"
              >
                <component :is="getIcon(item.icon)" />
              </div>
              <span class="co-living-benefits__item-title" :class="{'text-center--desktop text-center--tablet text-center--mobile':forHome}">{{ item.title }}</span> 
            </div>
            <section class="co-living-benefits__item-button" data-entrance="from-left">
              <button aria-label="book-now" class=" btn--dark-green" @click="clickBookCta">
                {{ bookText }}
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import getStatic from '~/utils/get-static';
import pascalCase from 'pascal-case';
import { forceObject } from '~/utils/processor';
import { getBookingStayPath } from '~/utils/booking';

import Onsite from '~/assets/icons/38/24.svg?inline';
import Bed from '~/assets/icons/38/bed.svg?inline';
import Bills from '~/assets/icons/38/bill.svg?inline';
import Book from '~/assets/icons/38/book.svg?inline';
import Chat from '~/assets/icons/38/chat.svg?inline';
import Events from '~/assets/icons/38/events.svg?inline';
import Facebook from '~/assets/icons/38/facebook.svg?inline';
import Film from '~/assets/icons/38/film.svg?inline';
import Games from '~/assets/icons/38/games.svg?inline';
import Golf from '~/assets/icons/38/golf.svg?inline';
import Gym from '~/assets/icons/38/gym.svg?inline';
import Laundry from '~/assets/icons/38/laundry.svg?inline';
import Pillows from '~/assets/icons/38/pillows.svg?inline';
import Pool from '~/assets/icons/38/pool.svg?inline';
import Sauna from '~/assets/icons/38/sauna.svg?inline';
import Shower from '~/assets/icons/38/shower.svg?inline';
import Cleaning from '~/assets/icons/38/spray.svg?inline';
import Tv from '~/assets/icons/38/tv.svg?inline';
import Wifi from '~/assets/icons/38/wifi.svg?inline';
import Wine from '~/assets/icons/38/wine.svg?inline';
import Workspace from '~/assets/icons/38/workspace.svg?inline';


import Rent from '~/assets/icons/38/rent.svg?inline';
import Concierge from '~/assets/icons/38/24.svg?inline';
import Broadband from '~/assets/icons/38/wifi.svg?inline';
import Coworking from '~/assets/icons/38/coworking.svg?inline';
import Communal from '~/assets/icons/38/workspace.svg?inline';

import { BREAKPOINTS, BREAKPOINT_MD, TABNAME_LONG_STAY, TABNAME_SHORT_STAY } from '~/utils/constants';

export default {
  components: {
    Onsite,
    Bed,
    Bills,
    Book,
    Chat,
    Events,
    Facebook,
    Film,
    Games,
    Golf,
    Gym,
    Laundry,
    Pillows,
    Pool,
    Sauna,
    Shower,
    Cleaning,
    Tv,
    Wifi,
    Wine,
    Workspace,
    Rent,
    Concierge,
    Broadband,
    Coworking,
    Communal,
  },
  props: {
    forHome: { type: Boolean, default: false },
    bookText: { type: String, default: 'Book Now' },
    image: {
      type: Object,
      default() {
        return {
          url: getStatic('/images/content/co-living-benefits/green-pattern.png'),
          description: '',
        };
      },
    },
    id: { type: String, default: '' },
    title: { type: String, default: 'Co-living at The Collective', required: true },
    intro: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    useInternalCtaAsAnchorLink: {
      type: Array,
      default() {
        return [
          {
            name: 'No',
            codename: 'no',
          },
        ];
      },
    },
  },
  data() {
    return {
      imageSizes: {
        default: [114, 115],
        sizes: [
          { breakpoint: BREAKPOINTS[BREAKPOINT_MD], width: 400, height: 323 },
        ],
      },
      longStayTabName: TABNAME_LONG_STAY,
      shortStayTabName: TABNAME_SHORT_STAY,
    };
  },
  computed: {
    getItemClass: function() {
      return {
        'for-home col-lg-3 col-6': this.$props.forHome,
        'col-6 col-lg-4': !this.forHome,
      };
    },
  },
  mounted() {
  },
  methods: {
    clickBookCta() {
      if ( this.useInternalCtaAsAnchorLink[0] && this.useInternalCtaAsAnchorLink[0].codename === 'yes') {
        const ele = document.querySelector('#co-working');
        ele && ele.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        this.openStay();
      }
    },
    openStay() {
      if (this.forHome) {
        this.$modal.show('booking');
      } else {
        if (this.$parent.bookingOptions && this.$parent.bookingOptions.bookingUrl) {
          window.open(this.$parent.bookingOptions.bookingUrl);
        } else {
          const { location } = this.getLocation();
          const { enabled=[] } = this.$parent.bookingOptions;
          const isShortStayEnabled = enabled.some((type)=>type.name.toLowerCase().indexOf('short') >= 0);

          this.$modal.show('booking', {
            location,
            bookingType: isShortStayEnabled ? TABNAME_SHORT_STAY : TABNAME_LONG_STAY, 
          });
        }

      }
            
    },
    getIcon(icon) {
      const { codename } = forceObject(icon);
      return pascalCase(codename.toLowerCase());
    },
    getLocation() {
      const parent = this.$parent;
      const path = getBookingStayPath(parent.$attrs.slug);
      const location = parent.title;
      return { location, path };
        
    },
  },
};
</script>

<style lang="scss">
@import '~/assets/scss/atomic/modules/_co-living-benefits.scss';
</style>
